<template>
    <a-spin :spinning="spinning">
        <div class="modal-header">
            <div class="modal-title">
                <span v-if="id">{{ l("Edit") }}</span>
                <span v-if="!id">{{ l("Create") }}</span>
            </div>
        </div>
        <a-form :form="form" @submit="handleSubmit" layout="horizontal">
            <a-row :gutter="24">
                <a-col span="24">
                    <!-- 题目类型 -->
                    <a-form-item :label="l('ExamTitleType')">
                        <a-radio-group v-model="ExamTitleType" @change="titleTypeChange">
                            <a-radio-button v-for="item in ExamTitleTypeItems" :value="item.value">{{item.text}}</a-radio-button>
                        </a-radio-group>
                    </a-form-item>
                </a-col>
            </a-row>
            <a-row :gutter="24">
                <a-col span="24">
                    <!--题干-->
                    <a-form-item :label="l('TitleBody')">
                        <a-textarea :placeholder="l('TitleBody')" :rows="4" :cols="50" v-decorator="['TitleBody', {rules: [{ required: true, message: l('ThisFieldIsRequired')}]}]" />
                    </a-form-item>
                </a-col>
            </a-row>
            <a-row :gutter="24">
                <a-col span="24">
                    <!--解析-->
                    <a-form-item :label="l('ExamDescription')">
                        <a-textarea :placeholder="l('ExamDescription')" :rows="4" :cols="50" v-decorator="['ExamDescription', {rules: []}]" />
                    </a-form-item>
                </a-col>
            </a-row>
            <a-row :gutter="24">
                <a-col span="24">
                    <!--选项-->
                    <p>{{l("AddTitleItem")}}：</p>
                    <a-button type="primary" icon="plus" size="small" @click="addTitleItem" v-if="MaxTitleItem">{{l("CreateUserDownloadConfig")}}</a-button>
                    <div class="title_item" v-for="(item,index) in TitleItems" :key="item.name">
                        {{item.name}}<a-input type="text" v-model="item.value" style="width:500px;"></a-input> &nbsp;
                        <a-checkbox v-model="item.isAnswer" @click="checkItem(item)">&nbsp;</a-checkbox>&nbsp;{{l("IsAnswer")}}&nbsp;&nbsp;
                        <a-button v-if="index == TitleItems.length-1" type="danger" size="small" icon="close" @click="removeTitleItem">{{l("DeleteUserDownloadConfig")}}</a-button>
                    </div>
                </a-col>
            </a-row>
            <a-row :gutter="24">
                <a-col span="24">
                    <a-form-item class="btn--container" style="text-align: center">
                        <a-button type="button" @click="close()">
                            {{ l("Cancel") }}
                        </a-button>
                        <a-button type="primary" html-type="submit">
                            {{ l("Save") }}
                        </a-button>
                    </a-form-item>
                </a-col>
            </a-row>
        </a-form>
    </a-spin>
</template>

<script>
    import { ModalComponentBase } from "@/shared/component-base";
    import { ModalHelper } from "@/shared/helpers";

    export default {
        mixins: [ModalComponentBase],
        name: "create-or-edit-project",
        data() {
            return {
                // 获取到的数据
                entity: {},
                spinning: false,
                singleNum: 0,
                singleTotal: 0,
                multNum: 0,
                multTotal: 0,
                IsRequiredCourse: false,
                ExamTitleType: '1',
                MaxTitleItem: true,
                ExamTitleTypeItems: [{ value: '1', text: this.l("单选题") }, { value: '2', text: this.l("MultipleTitle") }],
                TitleItems: []
            };
        },
        components: {},
        beforeCreate() {
            this.form = this.$form.createForm(this, { name: "trainsave" });
        },
        created() {
            this.fullData(); // 模态框必须,填充数据到data字段
        },
        mounted() {
            this.getData();
        },
        methods: {
            /**
             * 获取数据
             */
            getData() {
                if (this.id && this.id.toString().indexOf('-') > 0) {
                    this.spinning = true;
                    let options = {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json-patch+json",
                        },
                        url: this.$apiUrl + '/api/services/app/Train/GetQuestionDetail',
                        params: { guid: this.id }
                    };
                    this.$api.request(options).then(res => {
                        if (res.status == 200) {
                            let values = res.data;
                            this.ExamTitleType = values.questionType.toString();
                            this.form.setFieldsValue({
                                TitleBody: values.stem,
                                ExamDescription: values.analysis
                            });
                            let length = values.trainTestQuestionOptions.length;

                            if (values.questionType == 1) {
                                if (length >= 4) {
                                    this.MaxTitleItem = !this.MaxTitleItem;
                                }
                            } else {
                                if (length > 4) {
                                    this.MaxTitleItem = !this.MaxTitleItem;
                                }
                            }

                            for (let i = 0; i < values.trainTestQuestionOptions.length; i++) {
                                let item = values.trainTestQuestionOptions[i];
                                this.TitleItems.push({
                                    name: item.optionKey + "：",
                                    value: item.optionValue,
                                    isAnswer: item.isTrue
                                });
                            }
                            
                        } else {
                            abp.message.warn(res.statusText);
                        }
                    }).catch(e => {
                        console.error(e);
                        abp.message.warn(this.l("DefaultErrorMessage"));
                    }).finally(() => {
                        this.spinning = false;
                    })
                }
            },
            /**
             * 提交表单
             */
            handleSubmit(e) {
                e.preventDefault();
                this.form.validateFieldsAndScroll((err, values) => {
                    if (!err) {
                        if (this.TitleItems.length < 2) {
                            abp.message.warn(this.l("RequiredTwoTitle"));
                            return false;
                        }
                        if (this.TitleItems[0].value == '' || this.TitleItems[1].value == '') {
                            abp.message.warn(this.l("RequiredTwoTitle"));
                            return false;
                        }
                        var rows = this.TitleItems.filter(p => p.isAnswer);
                        if (this.ExamTitleType == "1") {
                            if (!rows || rows.length != 1) {
                                abp.message.warn(this.l("RequiredOneCollectAnswer"));
                                return false;
                            }
                        } else {
                            if (!rows || rows.length < 1) {
                                abp.message.warn(this.l("AtlastTwoCollectAnswer"));
                                return false;
                            }
                        }

                        let data = {
                            "questionType": this.ExamTitleType,
                            "stem": values.TitleBody,
                            "analysis": values.ExamDescription,
                            "trainSceneGuid": this.trainId,
                            "trainTestQuestionOptions": [],
                            "guid": null,
                            "answer":''
                        };
                        for (let i = 0; i < this.TitleItems.length; i++) {
                            let it = this.TitleItems[i];
                            let value = it.value.replace(" ","");
                            if (value == "") {
                                abp.message.warn(this.l("NotEmptyTitleItem"));
                                return false;
                            }
                            if (it.isAnswer) {
                                data.answer += it.name.replace("：","") + ",";
                            }
                            data.trainTestQuestionOptions.push({
                                optionKey: it.name.replace("：", ""),
                                optionValue: it.value,
                                isTrue: it.isAnswer
                            });
                        }
                        if (data.answer != '') {
                            data.answer = data.answer.substring(0, data.answer.length - 1);
                        }
                        let options = {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json-patch+json",
                            },
                            url: '',
                            data: null
                        }
                        if (this.id && this.id.toString().indexOf('-') > 0) {
                            //修改
                            options.url = this.$apiUrl + '/api/services/app/Train/UpdateQuestion';
                            data.guid = this.id;
                            options.data = data;
                        } else {
                            //新增
                            delete data.guid;
                            options.data = data;
                            options.url = this.$apiUrl + '/api/services/app/Train/CreateQuestion';
                        }
                        this.spinning = true;
                        this.$api.request(options).then(res => {
                            if (res.status == 200) {
                                abp.message.success("操作成功");
                                this.success(true);
                            } else {
                                abp.message.warn(res.statusText);
                            }
                        }).catch(e => {
                            console.error(e);
                            abp.message.warn(this.l("DefaultErrorMessage"));
                        }).finally(() => {
                            this.spinning = false;
                        });
                    }
                });
            },
            addTitleItem() {
                if (this.TitleItems.length >= 6) {
                    this.MaxTitleItem = false;
                }
                this.TitleItems.push({
                    name: this.getTitleName(),
                    value: '',
                    isAnswer: false
                });
            },
            removeTitleItem() {
                this.TitleItems.splice(this.TitleItems.length - 1, 1);
                if (!this.MaxTitleItem) {
                    this.MaxTitleItem = true;
                }
            },
            getTitleName() {
                let len = this.TitleItems.length;
                switch (len) {
                    case 0:
                        return "A：";
                    case 1:
                        return "B：";
                    case 2:
                        return "C：";
                    case 3:
                        return "D：";
                    case 4:
                        return "E：";
                    case 5:
                        return "F：";
                    case 6:
                        return "G：";
                }
            },
            checkItem(item) {
                if (item.isAnswer == false) {
                    if (this.ExamTitleType == '1') {
                        var rows = this.TitleItems.filter(p => p.isAnswer && p.name != item.name);
                        if (rows && rows.length > 0) {
                            for (let i = 0; i < this.TitleItems.length; i++) {
                                let it = this.TitleItems[i];
                                if (it.name != item.name && it.isAnswer) {
                                    this.TitleItems[i].isAnswer = false;
                                }
                            }
                        }
                    }
                }
            },
            titleTypeChange(e) {
                for (let i = 0; i < this.TitleItems.length; i++) {
                    this.TitleItems[i].isAnswer = false;
                }
            }
        },
    };
</script>

<style lang="less" scoped>
    .btn--container .ant-form-item-children {
        display: block;
        text-align: center;
    }

    .pleaseSelect-text {
        font-size: 14px;
        padding: 0 14px;
        text-align: center;
        color: rgba(0, 0, 0, 0.65);
        font-weight: 400;
        line-height: 30px;
        margin-bottom: 0;
    }

    .title_item {
        margin: 3px 0 5px 0;
    }
</style>