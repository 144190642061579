<template>
    <a-spin :spinning="spinning">
        <!-- <page-header :title="l('ProjectUserManager')"></page-header> -->
            

            <!-- <react-table
                ref="listTable"
                :table-data="tableData"
                :total-items="totalItems"
                :showTotal="showTotalFun"
                :actions-type="actionsType"
                :columns="columns"
                :hide-row-selection="true"
                :scroll="{x: 0}"
                :is-full="true"
                :current-page="pageNumber"
                @emitRefreshData="clearFilterAndRefresh"
                @emitOnPageChange="updatePageChange"
                @emitShowSizeChange="updateShowSizeChange"
            > -->
            <ele-table
                :columns="columns"
                :table-data="tableData"
                :total-items="totalItems"
                :is-full="true"
                :actionsType="actionsType"
                :current-page="pageNumber"
                :hide-row-selection="true"
                @emitRefreshData="clearFilterAndRefresh"
                @emitOnPageChange="updatePageChange"
                @emitShowSizeChange="updateShowSizeChange"
            >
                <a-row :gutter="8" align="center">
                    <a-col class="gutter-row" :span="6">
                        <a-input v-model.trim="SearchDto.sceneName"
                                :placeholder="l('TrainName')" />
                    </a-col>
                    <a-col class="gutter-row" :span="4">
                        <a-button type="primary" @click="search" v-if="isGranted('Pages.TestPermissions.Query')">
                            {{ l("Search") }}
                        </a-button>
                        <a-button @click="refreshGoFirstPage"> {{ l("Reset") }}</a-button>
                    </a-col>
                </a-row>
            </ele-table>

            <a-table :data-source="tableData" :pagination="pagination" @change="tableChange" style="margin-top: 20px;" v-if="false">
                <!-- <a-table-column-group> -->
                    <a-table-column :key="1" align="center" :title="l('TrainHeaderSiteName')" data-index="name" />
                    <a-table-column :key="2" align="center" :title="l('TrainHeaderTemplate')" data-index="template">
                        <template slot-scope="text, scope">
                            <a-row :gutter="24">
                                <a-col :span="8">
                                    <p>{{l("CurrentTitle")}}：</p>
                                    <p><a-tag color="blue">{{l("SingerTitle")}}</a-tag>&nbsp;{{scope.single.nowNum}}</p>
                                    <p><a-tag color="green">{{l("MultipleTitle")}}</a-tag>&nbsp;{{scope.mult.nowNum}}</p>
                                </a-col>
                                <a-col :span="8">
                                    <p>{{l("ExamRule")}}：</p>
                                    <p><a-tag color="blue">{{l("SingerTitle")}}</a-tag>&nbsp;{{scope.single.num}}</p>
                                    <p><a-tag color="green">{{l("MultipleTitle")}}</a-tag>&nbsp;{{scope.mult.num}}</p>
                                </a-col>
                                <a-col :span="8">
                                    <p>{{l("ExamGroup")}}：</p>
                                    <p v-if="scope.sinGroup"><a-icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" /></p>
                                    <p v-if="scope.multGroup"><a-icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" /></p>
                                    <p v-if="!scope.sinGroup"><a-icon type="close-circle" theme="twoTone" twoToneColor="#eb2f96" /></p>
                                    <p v-if="!scope.multGroup"><a-icon type="close-circle" theme="twoTone" twoToneColor="#eb2f96" /></p>
                                </a-col>
                            </a-row>
                        </template>
                    </a-table-column>
                    <a-table-column :key="3" align="center" :title="l('Status')" data-index="status">
                        <template slot-scope="text, scope">
                            <a-tag :color="scope.status == 1 ? 'blue' : 'red'">{{scope.status == 1 ? l("Effective"): l("Invalid")}}</a-tag>
                        </template>
                    </a-table-column>
                    <a-table-column :key="4" align="center" :title="l('Action')" data-index="id">
                        <template slot-scope="text, scope">
                            <a class="tbl-edit" v-for="item in ActionList" :key="item.key" @click="emitItem(item, scope)">
                                <span v-if="item.key != 'disabled'"><a-icon :type="item.icon" />{{item.name}}&nbsp;&nbsp;</span>
                                <span v-if="item.key == 'disabled'"><a-icon :type="item.icon" />{{scope.status == 0 ? l("Enable") : l("Disabled")}}&nbsp;&nbsp;</span>
                            </a>
                        </template>
                    </a-table-column>
                <!-- </a-table-column-group> -->
            </a-table>

    </a-spin>
</template>
<script>
    import { AppComponentBase } from "@/shared/component-base";
    import { ModalHelper } from "@/shared/helpers";
    import ExamList from './examlist.vue';
    import axios from "axios";
    import moment from "moment";
    import EleTable from "@/components/ele-table";

    export default {
        mixins: [AppComponentBase],
        components: {EleTable},
        name: "projectmodule",
        data: function () {
            return {
                spinning: false,
                SearchDto: {
                    sceneName: ""
                },
                selectedRowKeys: [],
                ActionList: [],
                pagination: { current: 1, pageSize: 5, total: 1 },
                pageSizeOptions: ["5", "10", "20", "30", "40"],
                tableData: [],//{ id: '123', name: '测试', description: '测试好玩吗', trainTime: '2021-2-2 17:00', endTrainTime: '2021-2-3 17:00', testTime: '2021-2-5 17:00', endTestTime: '2021-2-5 18:00', status: 0, isCheck: true, createTime: '2021-2-1 17:00' }
                tableData: [],
                columns: [],
                // 总数
                totalItems: 0,
                // 当前页码
                pageNumber: 1,
                // 共多少页
                totalPages: 1,
                // 条数显示范围
                pagerange: [1, 1],
                // 显示条数
                pageSizeOptions: ["10", "20", "30", "40"],
                request: { sorting: "", maxResultCount: 50, skipCount: 0 }, //sorting {排序项 ASC/DESC}
                // list-table btns显示参数
                actionsType: {},
            };
        },
        created() {
            this.getData();
            this.initActionsType();
            this.initColumns()
        },
        methods: {
            initColumns() {
                let _this = this
                this.columns = [
                    {
                        title: this.l("TrainHeaderSiteName"),
                        dataIndex: "name",
                        sorter: false,
                        align: "center",
                        scopedSlots: { customRender: "name" },
                    },
                    {
                        title: this.l("CurrentTitle"),
                        dataIndex: "current",
                        sorter: false,
                        align: "center",
                        type: 'popover',
                        customRender: function (text, record, index) {
                            let child = _this.$createElement("a-tooltip", {
                                domProps: {
                                    innerHTML: `<a>查看</a>`,
                                },
                                attrs: { title: `
                                   ${_this.l("SingerTitle")}:${record.single.nowNum ? record.single.nowNum : 0}
                                    ${_this.l("MultipleTitle")}:${record.mult.nowNum ? record.mult.nowNum : 0}
                                `},
                                on: {
                                    click: function () {},
                                },
                            });
                            let obj = {
                                children: child,
                                attrs: {},
                                on: {},
                            };
                            return obj;
                        },
                        scopedSlots: { customRender: "" },
                    },
                    {
                        title: this.l("ExamRule"),
                        dataIndex: "rule",
                        sorter: false,
                        align: "center",
                        type: 'popover',
                        customRender: function (text, record, index) {
                            let child = _this.$createElement("a-tooltip", {
                                domProps: {
                                    innerHTML: `<a>查看</a>`,
                                },
                                attrs: { title: `
                                   ${_this.l("SingerTitle")}:${record.single.num ? record.single.num : 0}，是否组卷：${record.sinGroup ? '是' : '否'}；
                                    ${_this.l("MultipleTitle")}:${record.mult.num ? record.mult.num : 0}，是否组卷：${record.sinGroup ? '是' : '否'}
                                `},
                                on: {
                                    click: function () {},
                                },
                            });
                            let obj = {
                                children: child,
                                attrs: {},
                                on: {},
                            };
                            return obj;
                        },
                        scopedSlots: { customRender: "" },
                    },
                    // <a-tag :color="scope.status == 1 ? 'blue' : 'red'">{{scope.status == 1 ? l("Effective"): l("Invalid")}}</a-tag>
                    {
                        title: this.l("Status"),
                        dataIndex: "status",
                        sorter: false,
                        align: "center",
                        type: 'tag',
                        customRender: function (text, record, index) {
                            let child = _this.$createElement("a-tag", {
                            domProps: {
                                innerHTML: `${record.status == 1 ? _this.l("Effective") : _this.l("Invalid")}`,
                            },
                            attrs: {title: `${record.status == 1 ? _this.l("Effective") : _this.l("Invalid")}`, color: record.status == 1 ? 'blue' : 'blue'},
                            on: {
                                click: function () {},
                            },
                            });
                            let obj = {
                            children: child,
                            attrs: {},
                            on: {},
                            };
                            return obj;
                        },
                        scopedSlots: { customRender: "status" },
                    },
                    {
                        title: this.l("Actions"),
                        dataIndex: "actions",
                        sorter: false,
                        align: "center",
                        fixed: "right",
                        scopedSlots: { customRender: "actions" }, //
                    },
                ]
            },
            initActionsType() {
                let _this = this;
                let obj = {
                    type: "",
                    isShow: true,
                    fns: {
                    dropList: [
                        {
                            granted: true,
                            name: this.l("TitleManager"),
                            icon: "edit",
                            fn: (data) => {
                                ModalHelper.create(
                                    ExamList,
                                    { id: data.id },
                                    {
                                        width: "1000px",
                                        isChange: true,
                                    }
                                ).subscribe((res) => {
                                    if (res) {
                                        _this.refreshGoFirstPage();
                                    }
                                });
                            },
                        },
                    
                    ],
                    },
                };
                this.actionsType = obj;
            },
            _initActionsType() {
                let options = [
                    {
                        key: 'study',
                        name: this.l("TitleManager"),
                        icon: 'copy',
                        click: data => {
                            ModalHelper.create(
                                ExamList,
                                { id: data.id },
                                {
                                    width: "1000px",
                                    isChange: true,
                                }
                            ).subscribe((res) => {
                                if (res) {
                                    this.refreshGoFirstPage();
                                }
                            });
                        }
                    }
                ];
                this.ActionList = [...options];
            },
            // 清除条件并刷新
            clearFilterAndRefresh() {
                this.SearchDto = {
                    sceneName: '',
                };
                this.request = { sorting: "", maxResultCount: 50, skipCount: 0 };
                this.filterText = "";
                this.getData();
            },
            // 分页
            updatePageChange(newV) {
                let { page, pageSize } = newV;
                this.pageNumber = page;
                this.request.skipCount = (page - 1) * this.request.maxResultCount;
                this.getData();
            },
            updateShowSizeChange(newV) {
                let { current, size } = newV;
                this.pageNumber = 1;
                this.request.maxResultCount = size;
                this.getData();
            },
            showTotalFun() {
                const res = this.l(
                    "GridFooterDisplayText",
                    this.pageNumber,
                    this.totalPages,
                    this.totalItems,
                    this.pagerange[0],
                    this.pagerange[1]
                );
                return res;
            },
            emitItem(item, scope) {
                if (item.click && typeof item.click == "function") {
                    item.click(scope);
                }
            },
            search() {
                this.pageNumber = 1
                this.SearchDto.pageIndex = 1
                this.getData()
            },
            getData() {
                this.spinning = true;
                let url = this.$apiUrl + "/api/services/app/Train/PageList";
                let options = {
                    // data: { sceneName: this.SearchDto.sceneName, pageSize: this.pagination.pageSize, pageIndex: this.pagination.current },
                    data: { sceneName: this.SearchDto.sceneName, pageSize: this.request.maxResultCount, pageIndex: this.pagination.current },
                    method: "POST",
                    url: url,
                    headers: {
                        "Content-Type": "application/json-patch+json",
                    },
                };
                this.$api
                    .request(options)
                    .then((response) => {
                        if (response.status == 200) {
                            this.tableData.splice(0, this.tableData.length);
                            let res = response.data;
                            if (res.items && res.items.length > 0) {
                                this.tableData = res.items.map((item) => {
                                    const _single = this.getTitle(item.trainSceneTemplates, 1)
                                    const _mult = this.getTitle(item.trainSceneTemplates, 2)
                                    const _sinGroup = this.getTitle(item.trainSceneTemplates, 1) ? this.getTitle(item.trainSceneTemplates, 1).nowNum - this.getTitle(item.trainSceneTemplates, 1).num >= 0 : false

                                    return {
                                        id: item.guid,
                                        name: item.sceneName,
                                        description: item.introduce,
                                        trainTime: this.timeFormat(item.effectiveStartTime),
                                        endTrainTime: this.timeFormat(item.effectiveEndTime),
                                        testTime: this.timeFormat(item.testStartTime),
                                        endTestTime: this.timeFormat(item.testEndTime),
                                        // status: item.status,
                                        isCheck: item.isRequiredCourse,
                                        createTime: item.createTime,
                                        isCanTest: item.isCanTest,
                                        sinGroup: this.getTitle(item.trainSceneTemplates, 1) ? this.getTitle(item.trainSceneTemplates, 1).nowNum - this.getTitle(item.trainSceneTemplates, 1).num >= 0 : false,
                                        multGroup: this.getTitle(item.trainSceneTemplates, 2) ? this.getTitle(item.trainSceneTemplates, 2).nowNum - this.getTitle(item.trainSceneTemplates, 2).num >= 0 : false,
                                        testLengthTime: item.testLengthTime,
                                        studyNotifyUrl: item.studyNotifyUrl,
                                        testNotifyUrl: item.testNotifyUrl,
                                        verificationUrl: item.verificationUrl,
                                        single: this.getTitle(item.trainSceneTemplates, 1),
                                        mult: this.getTitle(item.trainSceneTemplates, 2),
                                        current: 'current',
                                        currentList: [
                                            {label: this.l("SingerTitle") + '：', value: `${_single.nowNum ? _single.nowNum : 0}`},
                                            {label: this.l("MultipleTitle") + '：', value: `${_mult.nowNum ? _mult.nowNum : 0}`},
                                        ],
                                        rule: 'rule',
                                        ruleList: [
                                            {
                                                label: this.l("SingerTitle") + '：', 
                                                value: `${_single.num ? _single.num : 0}，是否组卷：${_sinGroup ? '是' : '否'}`
                                            },
                                            {
                                                label: this.l("MultipleTitle") + '：', 
                                                value: `${_mult.num ? _mult.num : 0}，是否组卷：${_sinGroup ? '是' : '否'}`
                                            },
                                        ],
                                        status: item.status == 1 ? this.l("Effective") : this.l("Invalid"),
                                        statusColor: item.status == 1 ? 'blue' : 'red',

                                    }
                                });

                                console.log(this.tableData)

                                this.pagination.total = res.totalItems

                                this.totalItems = res.totalItems;
                                this.pagerange = [
                                    (this.pageNumber - 1) * this.request.maxResultCount + 1,
                                    this.pageNumber * this.request.maxResultCount,
                                ];
                                // this.totalPages = Math.ceil(
                                //     res.totalItems / this.request.maxResultCount
                                // );
                                this.totalPages = res.totalPages
                            }
                        }
                    })
                    .catch((e) => {
                        console.error(e);
                        abp.message.warn(this.l("DefaultErrorMessage"));
                    })
                    .finally(() => {
                        this.spinning = false;
                    });
            },
            tableChange(pagination, filters, sorter) {
                const pager = { ...this.pagination };
                pager.current = pagination.current;
                this.pagination = pager;
                this.getData();
            },
            timeFormat(time) {
                return time.toString().replace("T", " ");
            },
            getTitle(template, type) {
                var rows = template.filter(e => e.questionType == type);
                if (rows && rows.length >= 1) {
                    return rows[0];
                }
                return '';
            },
            refreshGoFirstPage() {
                this.SearchDto.sceneName = "";
                this.SearchDto.pageIndex = 1;
                this.getData();
            }
        },
    };
</script>
<style lang="less" scoped>
    .btn--container {
        margin-top: 20px;
    }

    .btn--footer {
        margin-bottom: 20px;
    }

    .tbl-edit {
        display: block;
        margin-top: 3px;
    }
</style>