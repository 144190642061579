<template>
    <a-spin :spinning="spinning">
        <!-- <page-header :title="l('ProjectUserManager')"></page-header> -->
        <a-card :bordered="false">
            <a-row :gutter="24" type="flex" align="left">
                <a-col class="gutter-row" :span="8" align="center">
                    <a-button type="primary" @click="createOrEdit" v-if="isGranted('Pages.TestPermissions.QuestionContext')">
                        {{ l("createdAt") }}
                    </a-button>
                    <a-button @click="getData">
                        {{ l("Refresh") }}
                    </a-button>
                </a-col>
            </a-row>

            <a-table :data-source="tableData" style="margin-top: 20px;">
                <!-- <a-table-column-group>  -->
                    <a-table-column :key="1" align="center" :title="l('TitleBody')" data-index="stem" width="500px" />
                    <a-table-column :key="2" align="center" :title="l('ExamDescription')" data-index="analysis" />
                    <a-table-column :key="3" align="center" :title="l('ExamChoose')" data-index="trainTestQuestionOptions" width="100px" >
                        <template slot-scope="text, scope">
                            <div v-for="item in text" :key="item.optionKey" class="title-item">
                                <a-tag  :color="item.isTrue ? 'green' : 'red'">{{item.optionKey + ':&nbsp;' + item.optionValue}}</a-tag>
                            </div>
                        </template>
                    </a-table-column>
                    <a-table-column :key="4" align="center" :title="l('Action')" data-index="guid" fixed="right">
                        <template slot-scope="text, scope">
                            <a class="tbl-edit" v-for="item in ActionList" :key="item.key" @click="emitItem(item, scope)">
                                <span v-if="item.key != 'disabled'"><a-icon :type="item.icon" />{{item.name}}&nbsp;&nbsp;</span>
                                <span v-if="item.key == 'disabled'"><a-icon :type="item.icon" />{{scope.status == 0 ? l("Enable") : l("Disabled")}}&nbsp;&nbsp;</span>
                            </a>
                        </template>
                    </a-table-column>
                <!-- </a-table-column-group> -->
            </a-table>
        </a-card>

    </a-spin>
</template>
<script>
    import { ModalComponentBase } from "@/shared/component-base";
    import { ModalHelper } from "@/shared/helpers";
    import AddOrEdit from './addoredit.vue';
    import axios from "axios";
    import moment from "moment";

    export default {
        mixins: [ModalComponentBase],
        name: "projectmodule",
        data: function () {
            return {
                spinning: false,
                selectedRowKeys: [],
                ActionList: [],
                tableData: [],//{ id: '123', name: '����', description: '���Ժ�����', trainTime: '2021-2-2 17:00', endTrainTime: '2021-2-3 17:00', testTime: '2021-2-5 17:00', endTestTime: '2021-2-5 18:00', status: 0, isCheck: true, createTime: '2021-2-1 17:00' }
            };
        },
        methods: {
            initActionsType() {
                let options = [
                    {
                        key: 'edit',
                        name: this.l("Edit"),
                        icon: 'copy',
                        click: data => {
                            this.createOrEdit(data.guid);
                        }
                    },
                    {
                        key: 'delete',
                        name: this.l("DeleteUserDownloadConfig"),
                        icon: 'close',
                        click: data => {
                            abp.message.confirm(this.l("ConfirmDeleteWarningMessage"), this.l("HintInformation"), res => {
                                if (res) {
                                    this.spinning = true;
                                    let url = this.$apiUrl + "/api/services/app/Train/DeleteQuestion";
                                    this.$api.request({
                                        url: url,
                                        method: 'POST',
                                        headers: {
                                            "Content-Type": "application/json-patch+json",
                                        },
                                        data: { guid: data.guid}
                                    }).then(res => {
                                        if (res.status == 200) {
                                            abp.message.success(this.l("SuccessfullyDeleted"));
                                            this.getData();
                                        } else {
                                            abp.message.error(res.statusText);
                                        }
                                    }).catch((e) => {
                                        console.error(e);
                                        abp.message.warn(this.l("DefaultErrorMessage"));
                                    }).finally(() => {
                                        this.spinning = false;
                                    });
                                }
                            });
                        }
                    }
                ];
                this.ActionList = [...options];
            },
            emitItem(item, scope) {
                if (item.click && typeof item.click == "function") {
                    item.click(scope);
                }
            },
            getData() {
                this.spinning = true;
                let url = this.$apiUrl + "/api/services/app/Train/GetTrainTestQuestion";
                let options = {
                    params: { trainSceneGuid: this.id },
                    method: "GET",
                    url: url,
                    headers: {
                        "Content-Type": "application/json-patch+json",
                    },
                };
                this.$api
                    .request(options)
                    .then((response) => {
                        if (response.status == 200) {
                            this.tableData.splice(0, this.tableData.length);
                            let res = response.data;
                            if (res && res.length > 0) {
                                this.tableData = [...res];
                            }
                        }
                    })
                    .catch((e) => {
                        console.error(e);
                        abp.message.warn(this.l("DefaultErrorMessage"));
                    })
                    .finally(() => {
                        this.spinning = false;
                    });
            },
            refreshGoFirstPage() {
                this.getData();
            },
            createOrEdit(id) {
                ModalHelper.create(
                    AddOrEdit,
                    { id: id, trainId: this.id },
                    {
                        width: "550px",
                    }
                ).subscribe((res) => {
                    if (res) {
                        this.getData();
                    }
                });
            }
        },
        created() {
            this.fullData();
        },
        mounted() {
            this.getData();
            this.initActionsType();
        }
    };
</script>
<style lang="less" scoped>
    .btn--container {
        margin-top: 20px;
    }

    .btn--footer {
        margin-bottom: 20px;
    }

    .tbl-edit {
        display: block;
        margin-top: 3px;
    }
    .title-item{
        margin:3px 0 5px 0;
        text-align:center;
    }
</style>